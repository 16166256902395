import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import ThankYou from "../components/sections/thank-you";
//import BlogSection from "../components/sections/blog-section";
//import SeoWissenBanner from "../components/elements/seo-wissen-banner";

function ThankYouPage() {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="SEO Hamburg 🥇 Suchmaschinenoptimierung vom Experten"
      />
      <ThankYou />
      {/* <SeoWissenBanner /> */}
    </Layout>
  );
}

export default ThankYouPage;
